import React from 'react'
import Layout from '../../../components/layout'
import { Link } from 'gatsby'

const Respiratory = () => (
  <Layout>
    <section className="section-padding-large biq-bg-prim biq-text-white">
      <div className="container">
        <h1>Respiratory Health</h1>
      </div>
    </section>
    <section className="section-padding biq-bg-white biq-text-black select-none">
      <div className="container">
        {/* <h2 className="text-left mt-6">Respiratory 19 Best Practices: Breathing</h2> */}
        <div className="flex biq-text-md mt-6">
          Respiratory Health comprises illnesses such as COPD (asthma,
          bronchitis); it is diagnosed through various pulmonology tests such as
          Spirometry (which looks at lung capacity and velocity). The causes are
          varied: smoking, exposure to kitchen oil, even being overweight or
          underweight can cause your lungs not to “vent” properly. Sometimes the
          cause of decreased lung function can’t be pinpointed. The treatments
          may include inhalers, or some basic work using incentive spirometers
          (the plastic hand held boxes with balls you try to move with the
          breath) or coaching to stop and take a deep breath by respiratory
          physios. Sometimes a respiratory therapist may use what is called an
          Inspiratory Muscle Trainer, although direction to make sure that
          specifically a diaphragmatic breath is sorely missing. Pursed lip
          breathing is often the call to action, this slows a person from
          hyperventilating.
        </div>
        <div className="flex biq-text-md mt-6">
          A tremendously important adjust to these recommendations are
          correcting the mechanics of the breath so that a more controlled
          breath becomes natural. A biomechanically sound breath also means the
          exhale is more efficient, which is critical in taking an efficient
          inhale and completely taking a full exhale.{' '}
        </div>
        <h3 className="text-left mt-6">COVID/Flu?</h3>
        <div className="flex biq-text-md mt-6">
          <span>
            Making sure your lungs are covid ready is very important in being
            able to recover if you contract Covid. Having a efficient inhale and
            exhale, and strong breathing muscles not only means your immune
            system is better armed, it means that you will have the knowledge to
            continue to breathe and have less chances of COVID turning into
            pneumonia. If it turn to pneumonia having an efficient cough is the
            most important thing. That your breathing be good prior to the
            diagnosis is key. More information about COVID{' '}
            <Link to="/topics/covid" className="font-bold biq-text-prim">
              here
            </Link>
          </span>
        </div>

        <div className="flex biq-text-md mt-6">
          New research shows that people may have lung scarring and dyspnea post
          COVID. Breathing exercises make sure you are using all the of the
          lungs that are left, and that your breathing muscles are strong and
          can continue to ventilate your body well. Most people do not have
          strong breathing muscles because they don’t train them though research
          shows breathing muscle training is very effective. Lung scarring,
          inefficient breathing mechanics (not using all of the lungs) and weak
          breathing muscles means post covid you are breathing worse, and puts
          you at risk for recurrent respiratory problems.
        </div>

        <h3 className="text-left mt-6">
          Anxiety/Stress and Respiratory Health
        </h3>
        <div className="flex biq-text-md mt-6">
          Stress makes respiratory problems worse. Knowing how to breathe in a
          way to bring your heart rate and stress levels down means better
          control of your symptoms. Just getting told “take a deep breath”
          doesn’t mean you will actually take one unless you know your BIQ score
          and are working on your breathing.
        </div>
        <h3 className="text-left mt-6">Respiratory Health: Younger Lung Age</h3>
        <div className="flex biq-text-md mt-6">
          Your breathing naturally starts to decline at age 29, but you have a
          lot of choices to keep your respiratory health functioning not just at
          your age level but even having “younger” lungs. ObvIouSly taking care
          of your lungs like you do your heart is first, then making sure the
          muscles that pump then up and deflate them are strong. Making sure
          your ribcage stays flexible is key.
        </div>

        <h2>Breathlessness</h2>
        <h3>(dyspnea, Air hunger, being winded)</h3>
        <div className="flex biq-text-md mt-6">
          Breathlessness is one of the most common complaints doctors hear.
          While medical conditions that cause breathlessness need to be
          assessed, breathlessness should not be brushed aside as unimportant, a
          natural part of aging, or something that “just relaxing” will take
          care of.
        </div>
        <div className="flex biq-text-md mt-6">
          Breathlessness can be caused by a wide array of conditions, from bad
          posture and tight clothing to lung diseases and heart problems.
          Regardless, strengthening your breathing muscles and making sure you
          are breathing in a biomechanically sound way can make a significant
          change in your feeling winded, short of breath, or experiencing “air
          hunger.”
        </div>
        <div className="flex biq-text-md mt-6">
          The urgent sensation that something is wrong with your breathing may
          be dismissed, but you probably have a recurring feeling that it is
          important, and it didn’t used to feel this way. The Breathing IQ can
          actually give you quantifiable information, education and we have free
          information here on what corrective exercises to do if your Location
          of Movement and Range of Motion need work (more on that later).
        </div>
        <div className="flex biq-text-md mt-6">
          If you feel breathless, it’s not in your head. This is an important
          symptom that deserves attention. While you make sure you are assessing
          whether there is a undiagnosed medical problem that you have not
          treated, you can also take matters into your hands and understand and
          treat your respiratory health with the same importance that you give
          your heart health.
        </div>
      </div>
    </section>
    <section
          id="biqMockup"
          className="section-padding biq-bg-gray"
          // style={{
          //   background: `url(${this.props.data.biqBackground.edges[0].node.childImageSharp.fluid.src})`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          //   backgroundBlendMode: 'darken',
          //   backgroundPositionY: '1',
          // }}
        >
          <div className="flex absolute">
            {/* <div className="w-full">
              <Img
                fluid={
                  this.props.data.biqBackground.edges[0].node.childImageSharp
                    .fluid
                }
              />
            </div> */}
          </div>
          <div className="container">
            {/* BIQ  */}
            <div className="flex flex-row flex-wrap my-24">
              {/* <div className="flex w-full xl:w-1/2"> */}
              {/* <div className="w-full">
                  <Img
                    fluid={
                      this.props.data.biqMobile.edges[0].node.childImageSharp
                        .fluid
                    }
                  />
                </div> */}
              {/* </div> */}
              <div className="flex flex-col w-full place-items-center justify-center">
                <h1 className="uppercase biq-text-prim">Take the BIQ</h1>
                <h2 className="flex font-bold my-6 biq-text-xl text-center  biq-text-prim">
                  You can't change what you don't measure
                </h2>
                <span className="flex font-normal my-6">
                  <Link to="/biq" className="btn-prim biq-text-white">
                    Find Out Your BreathingIQ
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </section>
  </Layout>
)

export default Respiratory
